<template>
  <a-spin :spinning="loading">
    <a-row type="flex" class="flex-column">
      <p class="text-muted mt-4">
        To receive cash payment, please enter payment code into the field below.
      </p>

      <a-input
        size="large"
        placeholder="Enter payment code"
        pattern="[0-9]+"
        style="width: 300px"
        class="mb-4"
        v-model="paymentCode"
      />

      <a-button
        @click="confirmPayment"
        type="primary"
        size="large"
        style="width: 200px"
        :disabled="!paymentCode"
      >
        Receive Cash Payment
      </a-button>
    </a-row>
  </a-spin>
</template>
<script>
import ProfileMixin from "@/mixins/Profile";
import { mapActions } from "vuex";
import { get } from "lodash";

import { CONFIRM_CASH_PAYMENT } from "@/store/actions";

export default {
  mixins: [ProfileMixin],
  data() {
    return {
      transaction: {},
      loading: false,

      info: {
        title: "",
        success: false,
        message: "",
      },
      paymentCode: "",
    };
  },
  methods: {
    ...mapActions("misc", {
      confirmCashPayment: CONFIRM_CASH_PAYMENT,
    }),

    async confirmPayment() {
      try {
        this.loading = true;

        if (!this.paymentCode || !this.paymentCode.trim().length) {
          throw new Error("Please provide payment code");
        }

        const response = await this.confirmCashPayment(this.paymentCode);

        this.$notification.success({
          message: "Cash payment confirmed",
        });

        this.$router.push(
          `/${this.getUserAccountProfileModule}/transaction/${response.data._id}`
        );
      } catch (error) {
        this.$notification.error({
          title: "Error processing cash payment",
          message: get(error, "response.data.message") || error.message,
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style scoped>
.ant-input.ant-input-disabled {
  background-color: white;
}
</style>
