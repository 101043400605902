<template>
  <confirm-or-receive-cash-transaction-form
    transaction-type="confirm-transaction"
  />
</template>

<script>
import ConfirmOrReceiveCashTransactionForm from "@/components/transactions/ConfirmOrReceiveCashTransactionForm.vue";

export default {
  components: {
    ConfirmOrReceiveCashTransactionForm,
  },
};
</script>
