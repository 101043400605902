<template>
  <a-spin :spinning="loading">
    <div>
      <a-row type="flex" class="flex-column">
        <p class="text-muted mt-4">
          To confirm ticket / transaction, please enter transaction number into
          the field below.
        </p>

        <a-input
          size="large"
          placeholder="Enter transaction number"
          pattern="[0-9]+"
          v-model="transaction_no"
          style="width: 300px"
          class="mb-4"
        />

        <a-button
          @click="handleSubmit"
          type="primary"
          size="large"
          style="width: 200px"
          :disabled="!transaction_no"
        >
          Confirm Transaction
        </a-button>
      </a-row>

      <a-modal v-model="visible" title="Transaction" :footer="null">
        <a-row
          type="flex"
          class="flex-column align-items-center justify-content-center"
        >
          <a-col
            ><i
              class="fa fa-exclamation-triangle font-size-48"
              style="color: red"
              v-if="!info.success"
            ></i>
            <i
              class="fa fa-check font-size-48"
              style="color: green"
              v-if="info.success"
            ></i>
          </a-col>
          <a-col>
            <h4>
              {{ info.title }}
            </h4>
          </a-col>
          <a-col>
            <p>
              {{ info.message }}
            </p>
          </a-col>
        </a-row>
      </a-modal>
    </div>
  </a-spin>
</template>
<script>
import ProfileMixin from "@/mixins/Profile";
import { get } from "lodash";

import { mapActions } from "vuex";
import { CONFIRM_TRANSACTION } from "@/store/actions";

export default {
  mixins: [ProfileMixin],
  data() {
    return {
      transaction_no: "",
      loading: false,

      visible: false,
      info: {
        title: "",
        success: false,
        message: "",
      },
    };
  },
  methods: {
    ...mapActions("misc", {
      confirmTransaction: CONFIRM_TRANSACTION,
    }),

    async handleSubmit() {
      try {
        if (!this.transaction_no || !this.transaction_no.trim()) {
          throw new Error("Transaction number required");
        }

        const response = await this.confirmTransaction({
          transaction_no: this.transaction_no,
        });

        const { data } = response;

        this.$notification.success({
          message: "Transaction validated successfully",
        });

        this.$router.push(
          `/${this.getUserAccountProfileModule}/transaction/${data._id}`
        );
      } catch (error) {
        this.info = {
          title: get(error, "response.data.message") || error.message,
          success: false,
          message: "Transaction number could not be validated",
        };
        this.visible = true;
      }
    },
  },
};
</script>
<style scoped>
.ant-input.ant-input-disabled {
  background-color: white;
}
</style>
