<template>
  <div>
    <div>
      <receive-cash-transaction-form v-if="transactionType == 'receive-cash'" />
      <confirm-cash-transaction-form
        v-if="transactionType == 'confirm-transaction'"
      />
    </div>
  </div>
</template>

<script>
import ReceiveCashTransactionForm from "./ReceiveCashTransactionForm.vue";
import ConfirmCashTransactionForm from "./ConfirmCashTransactionForm.vue";

export default {
  components: {
    ReceiveCashTransactionForm,
    ConfirmCashTransactionForm,
  },
  props: {
    transactionType: {
      type: String,
      required: true,
    },
  },
};
</script>
